/* Register.js（完成版） */

import React, { useState, useEffect, useRef } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
/* 「Link」をimport↓ */
import { Navigate, Link } from "react-router-dom";
import {
  Input,
  Button,
  Form,
  message,
  Spin,
  Checkbox,
  Divider,
  Modal,
} from "antd";
import "./App.css";
import {
  EditOutlined,
  UserOutlined,
  LockOutlined,
  DownOutlined,
} from "@ant-design/icons";

const CheckboxGroup = Checkbox.Group;

const validateMessages = {
  types: {
    email: "有効なメールアドレスではありません。",
  },
};
const onFinish = (values) => {
  console.log(values);
};

const Register = () => {
  const [open, setOpen] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const passwordCheck = useRef(null);
  const [checkedList, setCheckedList] = useState();
  const [checkAll, setCheckAll] = useState(false);
  const [termsCheck, setTermsCheck] = useState(true);
  const plainOptions = [
    {
      label: "私は「利用規約」を確認し承諾します。",
      value: "私は「利用規約」を確認し承諾します。",
      disabled: termsCheck,
    },
    {
      label: "私は上記の「プライバシーポリシー」の内容を確認し同意します。",
      value: "私は上記の「プライバシーポリシー」の内容を確認し同意します。",
      disabled: termsCheck,
    },
    {
      label: "私は反社会勢力等ではありません。",
      value: "私は反社会勢力等ではありません。",
    },
  ];
  const onChange = (list) => {
    setCheckedList(list);
    setCheckAll(list.length === plainOptions.length);
  };

  function okButton() {
    setCheckedList([
      "私は「利用規約」を確認し承諾します。",
      "私は上記の「プライバシーポリシー」の内容を確認し同意します。",
    ]);
    setTermsCheck(false);
    setOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      sendSignInLinkToEmail(registerEmail);

      message.success(registerEmail + "として新規登録しました");
    } catch (error) {
      message.warning("メールアドレスまたはパスワードが間違っています");
      setLoading(false);
    }
  };

  const [user, setUser] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  return (
    <div
      className="center"
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "100px",
      }}
    >
      {user ? (
        <Navigate to={`/Top/`} />
      ) : (
        <>
          {loading && (
            <Spin size="large" style={{ margin: 200 }} tip="Loading" />
          )}
          {!loading && (
            <Form
              name="nest-messages"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <h1>新規登録する</h1>
              <p>
                ご利用にあたり、「利用規約」および「プライバシーポリシー」の内容にご承諾いただく必要があります。
              </p>
              <Button onClick={() => setOpen(true)} icon={<DownOutlined />}>
                「利用規約」および「プライバシーポリシー」
              </Button>
              <p>
                上記の内容を十分にご確認の上、ご承諾いただける場合には、下記のチェックボックスにチェックを入れてください。
              </p>
              <CheckboxGroup
                options={plainOptions}
                value={checkedList}
                onChange={onChange}
              />
              <h4>メールアドレス</h4>
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                style={{ width: 500 }}
                name="email"
                type="email"
                value={registerEmail}
                onChange={(e) => setRegisterEmail(e.target.value)}
                rules={[
                  {
                    type: "email",
                  },
                ]}
              />
              <h4>パスワード</h4>
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                style={{ width: 500 }}
                name="password"
                value={registerPassword}
                onChange={(e) => setRegisterPassword(e.target.value)}
                ref={passwordCheck}
              />
              <br></br>
              <br></br>
              <Button
                type="primary"
                onClick={handleSubmit}
                icon={<EditOutlined />}
                disabled={!checkAll}
              >
                登録する
              </Button>
              {/* ↓リンクを追加 */}
              <p>
                ログインは<Link to={`/login/`}>こちら</Link>
              </p>
              <Modal
                centered
                open={open}
                onOk={() => okButton()}
                onOK={() => setOpen(false)}
                okText="確認"
                onCancel={() => setOpen(false)}
                cancelText="キャンセル"
                width={1000}
              >
                <p>
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    利用規約
                  </h2>
                  <p>
                    この利用規約（以下、「本規約」といいます。）は、株式会社HappyQuality（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。当ウェブサイトのご利用に際しては、以下の利用規約に同意される場合のみご利用下さい。登録ユーザーの皆さま（以下、「ユーザー」といいます。）は、本利用規約に同意いただいたものとみなします。
                  </p>
                  <p>
                    <h4>第1条（適用） </h4>
                    <div>
                      1.　本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
                    </div>
                    <div>
                      2.　当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
                    </div>
                    <div>
                      3.　本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
                    </div>
                  </p>
                  <p>
                    <h4>第2条（利用登録）</h4>
                    <div>
                      1.　本サービスにおいては、登録希望者が本規約に同意の上、当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
                    </div>
                    <div>
                      2.　当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                    </div>
                    <p>
                      <div>
                        　1.　利用登録の申請に際して虚偽の事項を届け出た場合
                      </div>
                      <div>
                        　2.　本規約に違反したことがある者からの申請である場合
                      </div>
                      <div>
                        　3.　その他、当社が利用登録を相当でないと判断した場合
                      </div>
                    </p>
                  </p>
                  <p>
                    <h4>第3条（ユーザーIDおよびパスワードの管理）</h4>
                    <div>
                      1.　ユーザーは、自己の責任において、本サービスのユーザーID（メールアドレス）およびパスワードを適切に管理するものとします。
                    </div>
                    <div>
                      2.　ユーザーは、いかなる場合にも、メールアドレスおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当社は、メールアドレスとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのメールアドレスを登録しているユーザー自身による利用とみなします。
                    </div>
                    <div>
                      3.　メールアドレス及びパスワードが第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
                    </div>
                  </p>
                  <p>
                    <h4>第4条（利用料金および支払方法）</h4>
                    <div>
                      1.　ユーザーは、本サービスの有料部分の対価として、当社が別途定める利用料金を、当社が指定する方法により支払うものとします。
                    </div>
                    <div>
                      2.　ユーザーが利用料金の支払を遅滞した場合には、ユーザーは年14．6％の割合による遅延損害金を支払うものとします。
                    </div>
                  </p>
                  <p>
                    <h4>第5条（禁止事項）</h4>
                    <div>
                      ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
                    </div>
                    <p>
                      <div>1.　法令または公序良俗に違反する行為</div>
                      <div>2.　犯罪行為に関連する行為</div>
                      <div>
                        3.　当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
                      </div>
                      <div>
                        4.　本サービスの内容等、本サービスに含まれる著作権、商標権ほか当社の知的財産権を侵害する行為
                      </div>
                      <div>
                        5.　当社、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
                      </div>
                      <div>
                        6.　本サービスのネットワークまたはシステム等に過度な負荷を掛ける行為
                      </div>
                      <div>
                        7.　本サービスによって得られた情報を商業的に利用する行為
                      </div>
                      <div>
                        8.　当社のサービスの運営を妨害するおそれのある行為
                      </div>
                      <div>9.　不正アクセスをし、またはこれを試みる行為</div>
                      <div>
                        10.　本サービスその他の当社が提供するシステムに対するリバースエンジニアリングその他の解析行為
                      </div>
                      <div>
                        11.　他のユーザーに関する個人情報等を収集または蓄積する行為
                      </div>
                      <div>12.　不正な目的を持って本サービスを利用する行為</div>
                      <div>
                        13.　本サービスの他のユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
                      </div>
                      <div>14.　他のユーザーその他第三者に成りすます行為</div>
                      <div>
                        15.　本サービスの他のユーザーIDまたはパスワードを利用する行為
                      </div>
                      <div>
                        16.　当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                      </div>
                      <div>17.　面識のない異性との出会いを目的とした行為</div>
                      <div>
                        18.　当社のサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
                      </div>
                      <div>
                        19.　前各号の行為を直接または間接に惹起し、または容易にする行為
                      </div>
                      <div>20.　前各号の行為を試みること</div>
                      <div>21.　その他、当社が不適切と判断する行為</div>
                    </p>
                  </p>
                  <p>
                    <h4>第6条（本サービスの提供の停止等）</h4>
                    <div>
                      1.　当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                    </div>
                    <p>
                      <div>
                        　1.　本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
                      </div>
                      <div>
                        　2.　地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                      </div>
                      <div>
                        　3.　コンピュータまたは通信回線等が事故により停止した場合
                      </div>
                      <div>
                        　4.　その他、当社が本サービスの提供が困難と判断した場合
                      </div>
                      <div></div>
                    </p>

                    <div>
                      2.　当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
                    </div>
                  </p>
                  <p>
                    <h4>第7条（利用制限および登録抹消）</h4>
                    <p>
                      <div>
                        1.　当社は、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
                      </div>
                      <p>
                        <div>　1.　本規約のいずれかの条項に違反した場合</div>
                        <div>
                          　2.　登録事項に虚偽の事実があることが判明した場合
                        </div>
                        <div>　3.　料金等の支払債務の不履行があった場合</div>
                        <div>
                          　4.　当社からの連絡に対し、一定期間返答がない場合
                        </div>
                        <div>
                          　5.　本サービスについて、最終の利用から一定期間利用がない場合
                        </div>
                        <div>
                          　6.　その他、当社が本サービスの利用を適当でないと判断した場合
                        </div>
                      </p>
                    </p>
                    <div>
                      2.　当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
                    </div>
                  </p>
                  <p>
                    <h4> 第8条（退会）</h4>
                    ユーザーは、当社の定める退会手続により、本サービスから退会できるものとします。
                  </p>
                  <p>
                    <h4>第9条（保証の否認および免責事項）</h4>
                    <p>
                      <div>
                        1.　当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                      </div>
                      <div>
                        2.　当社は、ユーザーに対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
                      </div>
                      <div>
                        3.　当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、内容が第三者の知的財産権、人格権その他の権利の侵害しないこと、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
                      </div>
                      <div>
                        4.　当社は、本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
                      </div>
                      <div>
                        5.　前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は、ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
                      </div>
                      <div>
                        6.　当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
                      </div>
                    </p>
                  </p>
                  <p>
                    <h4>第10条（サービス内容の変更等）</h4>
                    当社は、ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
                  </p>
                  <p>
                    <h4>第11条（利用規約の変更）</h4>
                    <p>
                      <div>
                        1.　当社は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
                      </div>
                      <p>
                        <div>
                          　1.　本規約の変更がユーザーの一般の利益に適合するとき。
                        </div>
                        <div>
                          　2.　本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
                        </div>
                      </p>
                      <div>
                        2.　当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。
                      </div>
                    </p>
                  </p>
                  <p>
                    <h4>第12条（個人情報の取扱い）</h4>
                    当社は、本サービスの利用によって取得する個人情報については、当社「プライバシーポリシー」に従い適切に取り扱うものとします。
                  </p>
                  <p>
                    <h4>第13条（通知または連絡）</h4>
                    ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、ユーザーから、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時にユーザーへ到達したものとみなします。
                  </p>
                  <p>
                    <h4>第14条（権利義務の譲渡の禁止）</h4>
                    ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
                  </p>
                  <p>
                    <h4>第15条（準拠法・裁判管轄）</h4>
                    本規約の解釈にあたっては、日本法を準拠法とします。
                    本サービスに関して紛争が生じた場合には、静岡地方裁判所浜松支部を第一審の専属的合意管轄裁判所とします。
                  </p>
                  <p
                    style={{
                      textAlign: "right",
                    }}
                  >
                    以上
                  </p>
                  <p
                    style={{
                      textAlign: "right",
                    }}
                  >
                    附則 2022年4月1日施行
                  </p>
                </p>
                <p>
                  <h2
                    style={{
                      textAlign: "center",
                    }}
                  >
                    プライバシーポリシー
                  </h2>
                  <p>
                    <p>
                      株式会社HappyQualityは、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
                    </p>
                    <p>
                      <h4>第1条（個人情報の収集方法）</h4>
                      <div>当社は、以下の方法で個人情報を取得します。</div>
                      <p>
                        <div>
                          　1.　ユーザーから直接、個人情報の提供を受ける方法
                        </div>
                        <div>
                          　2.　ユーザーが当社サービスを利用する際に、自動的に個人情報を記録する方法
                        </div>
                      </p>
                    </p>
                    <p>
                      <h4>第2条（個人情報の利用目的）</h4>
                      <div>当社は、以下の目的で個人情報を利用します。</div>
                      <p>
                        <div>
                          　1.　本サービスの提供・運営・維持・保護及び改善のため
                        </div>
                        <div>
                          　2.　ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
                        </div>
                        <div>
                          　3.　ユーザーが利用中のサービスの新機能、更新情報、キャンペーン等及び当社が提供する他のサービスの案内のため
                        </div>
                        <div>
                          　4.　メンテナンス、規約等の変更等重要なお知らせなど必要に応じたご連絡のため
                        </div>
                        <div>
                          　5.　本サービスに関する当社の規約、ポリシー等に違反する行為に対応するため
                        </div>
                        <div>
                          　6.　ユーザーにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
                        </div>
                        <div>
                          　7.　有料サービスにおいて、ユーザーに利用料金を請求するため
                        </div>
                        <div>　8.　上記の利用目的に付随する目的</div>
                      </p>
                      <p>
                        <h4>第3条（利用目的の変更）</h4>
                        <p>
                          <div>
                            1.　当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
                          </div>
                          <div>
                            2.　利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
                          </div>
                        </p>
                      </p>
                      <p>
                        <h4>第4条（個人情報の第三者提供）</h4>
                        <p>
                          <div>
                            1.　当社は、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
                          </div>
                          <p>
                            <div>
                              　1.　人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                            </div>
                            <div>
                              　2.　公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                            </div>
                            <div>
                              　3.　国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                            </div>
                            <p>
                              <div>
                                　4.　予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
                              </div>
                              <p>
                                <div>
                                  　　1.　利用目的に第三者への提供を含むこと
                                </div>
                                <div>
                                  　　2.　第三者に提供されるデータの項目
                                </div>
                                <div>
                                  　　3.　第三者への提供の手段または方法
                                </div>
                                <div>
                                  　　4.　本人の求めに応じて個人情報の第三者への提供を停止すること
                                </div>
                                <div>　　5.　本人の求めを受け付ける方法</div>
                              </p>
                            </p>
                          </p>
                        </p>
                      </p>
                      <div>
                        2.　前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
                      </div>
                      <p>
                        <div>
                          　1.　当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                        </div>
                        <div>
                          　2.　合併その他の事由による事業の承継に伴って個人情報が提供される場合
                        </div>
                        <div>
                          　3.　個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
                        </div>
                      </p>
                    </p>
                    <p>
                      <h4>第5条（個人情報の開示）</h4>
                      <p>
                        <p>
                          <div>
                            1.　当社は、ユーザーから個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で当社の定める手続きに従い、遅滞なくこれを開示します（当該個人情報が存在しないときはその旨通知します。）。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあります。なお、個人情報の開示につきましては手数料（１件あたり１，０００円）を頂戴しておりますので、あらかじめご了承ください。
                          </div>
                          <p>
                            <div>
                              　1.　個人情報保護法その他の法令により当社が開示の義務を負わない場合
                            </div>
                            <div>
                              　2.　本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                            </div>
                            <div>
                              　3.　当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                            </div>
                            <div>　4.　その他法令に違反することとなる場合</div>
                          </p>
                          <div>
                            2.　前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
                          </div>
                        </p>
                      </p>
                      <p>
                        <h4>第6条（個人情報の訂正および削除）</h4>
                        <div>
                          1.　ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、個人情報保護法に基づき当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
                        </div>
                        <div>
                          2.　当社は、ユーザーご本人からのご請求であることを確認の上ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行うものとします。
                        </div>
                        <div>
                          3.　当社は、前項の規定に基づき訂正等を行った場合、または訂正等を行わない旨の決定をしたときは遅滞なく、これをユーザーに通知します。
                        </div>
                      </p>
                      <p>
                        <h4>第7条（個人情報の利用停止等）</h4>
                        <div>
                          1.　当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、個人情報保護法に基づきその利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、ユーザーご本人からのご請求であることを確認の上遅滞なく必要な調査を行います。
                        </div>
                        <div>
                          2.　前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。
                        </div>
                        <div>
                          3.　当社は、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
                        </div>
                        <div>
                          4.　前2項にかかわらず、利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
                        </div>
                      </p>
                      <p>
                        <h4>第8条（プライバシーポリシーの変更）</h4>
                        <div>
                          1.　本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
                        </div>
                        <div>
                          2.　当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
                        </div>
                      </p>
                      <p>
                        <h4>第9条（お問い合わせ窓口）</h4>
                        <div>
                          本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
                        </div>
                        <div>Eメールアドレス:tamai@happy-quality.jp</div>
                      </p>
                    </p>
                    <p
                      style={{
                        textAlign: "right",
                      }}
                    >
                      以上
                    </p>
                    <p
                      style={{
                        textAlign: "right",
                      }}
                    >
                      附則 2022年4月1日施行
                    </p>
                  </p>
                </p>
              </Modal>
              <Divider />
            </Form>
          )}
        </>
      )}
    </div>
  );
};

export default Register;
