import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";
import { Line } from "@ant-design/plots";
import { Spin, Space, theme, DatePicker, Empty, Button } from "antd";
import { db, auth } from "./FirebaseConfig";
import moment from "moment";

import "./App.css";

const { RangePicker } = DatePicker;

function Charts() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false); // フィルタリング状態を追跡する状態を追加

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      const fetchPosts = async () => {
        setLoading(true);
        try {
          const email = user.email;
          const snapshot = await getDocs(collection(db, email));
          const fetchedPosts = snapshot.docs.map((doc) => doc.data());
          // apertureがnullでないデータのみをフィルタリング
          const validPosts = fetchedPosts.filter(
            (post) => post.aperture !== null
          );
          setPosts(validPosts);
          setFilteredData(validPosts);
        } catch (error) {
          console.error("Error fetching posts: ", error);
        }
        setLoading(false);
      };
      fetchPosts();
    }
  }, [user]);

  const handleDateChange = (dates) => {
    if (dates) {
      const startDate = dates[0].startOf("day").valueOf();
      const endDate = dates[1].endOf("day").valueOf();
      setDateRange([dates[0], dates[1]]);
      const filtered = posts.filter((post) => {
        const postDate = moment(post.timestamp).valueOf();
        return postDate >= startDate && postDate <= endDate;
      });
      setFilteredData(filtered);
      setIsFiltered(true); // フィルタリング状態をtrueに設定
    } else {
      setDateRange([]);
      setFilteredData(posts);
      setIsFiltered(false); // フィルタリング状態をfalseに設定
    }
  };

  const handleReset = () => {
    setDateRange([]);
    setFilteredData(posts);
    setIsFiltered(false); // フィルタリング状態をfalseに設定
  };

  const data = filteredData.map((post) => ({
    ...post,
    timestamp: moment(post.timestamp).toISOString(),
  }));

  const COLOR_PLATE_10 = [
    "#5B8FF9",
    "#FF99C3",
    "#5D7092",
    "#F6BD16",
    "#E8684A",
    "#6DC8EC",
    "#9270CA",
    "#FF9D4D",
    "#269A99",
    "#FF99C3",
  ];

  const config = {
    data,
    xField: "timestamp",
    yField: "aperture",
    seriesField: "cultiverName",
    xAxis: {
      type: "time",
      mask: "YYYY-MM-DD HH:mm",
      title: {
        text: "撮影日時",
        style: {
          fontSize: 12,
        },
      },
    },
    yAxis: {
      title: {
        text: "平均気孔開度(µm)",
        style: {
          fontSize: 12,
        },
      },
    },
    legend: {
      position: "right-top",
    },
    color: COLOR_PLATE_10,
    point: { size: 5 },
    smooth: false,
    slider: {
      start: 0,
      end: 1.0,
      trendCfg: {
        isArea: true,
      },
    },
    regressionLine: {
      type: "linear", // 線形回帰
      style: {
        stroke: "red",
        lineWidth: 2,
      },
    },
  };

  return (
    <div
      style={{
        padding: 24,
        background: colorBgContainer,
        height: "98%",
      }}
    >
      {loading ? (
        <div className="spin">
          <Spin size="large" tip="Loading" />
        </div>
      ) : (
        <>
          {!user ? (
            <Navigate to={"/"} />
          ) : (
            <>
              <Space>
                <RangePicker onChange={handleDateChange} value={dateRange} />
                <Button onClick={handleReset} disabled={!isFiltered}>
                  リセット
                </Button>{" "}
                {/* フィルタリング状態に基づいてボタンを無効化 */}
              </Space>
              {filteredData.length > 0 ? (
                <Line {...config} />
              ) : (
                <Empty description="選択された日付範囲内にデータがありません" />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Charts;
