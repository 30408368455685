/* FirebaseConfig.js */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAgXkBOMojP07Sp80hQLuqWiPF6EiCbIow",
  authDomain: "stomatascope-fdf90.firebaseapp.com",
  databaseURL: "https://stomatascope-fdf90-default-rtdb.firebaseio.com",
  projectId: "stomatascope-fdf90",
  storageBucket: "stomatascope-fdf90.appspot.com",
  messagingSenderId: "320962689118",
  appId: "1:320962689118:web:7cfa164d3b1ecabb59dccf",
  measurementId: "G-GK3WGZXTSL",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const strage = getStorage(app);

export { db, strage, auth };
