import React from "react";
import { Card, Avatar, Typography, Image } from "antd";

const { Meta } = Card;
const { Title } = Typography;

const PlantCard = ({ coverImage, avatarImage, title, description }) => (
  <Card
    hoverable
    style={{ width: 240, margin: "16px" }}
    cover={<Image alt={title} src={coverImage} />}
  >
    <Meta
      avatar={<Avatar src={avatarImage} />}
      title={<Title level={4}>{title}</Title>}
      description={description}
    />
  </Card>
);

export default PlantCard;
