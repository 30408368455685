import React, { useState } from "react";
import {
  CarryOutOutlined,
  CheckOutlined,
  FormOutlined,
  DownOutlined,
} from "@ant-design/icons";
import torisetu0 from "./assets/images/manual/0.jpg";
import torisetu1 from "./assets/images/manual/1.jpg";
import torisetu2 from "./assets/images/manual/2.jpg";
import torisetu3 from "./assets/images/manual/3.jpg";
import torisetu4 from "./assets/images/manual/4.jpg";
import torisetu5 from "./assets/images/manual/5.jpg";
import torisetu6 from "./assets/images/manual/6.jpg";
import torisetu7 from "./assets/images/manual/7.jpg";
import torisetu8 from "./assets/images/manual/8.jpg";
import torisetu9 from "./assets/images/manual/9.jpg";
import torisetu10 from "./assets/images/manual/10.jpg";
import torisetu11 from "./assets/images/manual/11.jpg";
import torisetu12 from "./assets/images/manual/12.jpg";
import torisetu13 from "./assets/images/manual/13.jpg";
import torisetu14 from "./assets/images/manual/14.jpg";
import torisetu15 from "./assets/images/manual/15.jpg";
import torisetu16 from "./assets/images/manual/16.jpg";
import torisetu17 from "./assets/images/manual/17.jpg";
import torisetu18 from "./assets/images/manual/18.jpg";
import torisetu19 from "./assets/images/manual/19.jpg";
import torisetu20 from "./assets/images/manual/20.jpg";
import torisetu21 from "./assets/images/manual/21.jpg";
import torisetu22 from "./assets/images/manual/22.jpg";
import torisetu23 from "./assets/images/manual/23.jpg";
import torisetu24 from "./assets/images/manual/24.jpg";
import torisetu25 from "./assets/images/manual/25.jpg";

import { Image, Collapse, Space, Tree } from "antd";
const treeData = [
  {
    title: "使用上の注意",
    key: "0-0",
    children: [
      {
        title: "parent 1-0",
        key: "0-0-0",
        children: [
          {
            title: "leaf",
            key: "0-0-0-0",
          },
          {
            title: "leaf",
            key: "0-0-0-1",
          },
          {
            title: "leaf",
            key: "0-0-0-2",
          },
        ],
      },
      {
        title: "parent 1-1",
        key: "0-0-1",
        children: [
          {
            title: "leaf",
            key: "0-0-1-0",
          },
        ],
      },
      {
        title: "parent 1-2",
        key: "0-0-2",
        children: [
          {
            title: "leaf",
            key: "0-0-2-0",
          },
          {
            title: "leaf",
            key: "0-0-2-1",
          },
        ],
      },
    ],
  },
];
const UsersManual = () => {
  const width = "50%";
  const onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };
  return (
    <div>
      <Image width={width} src={torisetu0} />
      <Image width={width} src={torisetu1} />
      <Image width={width} src={torisetu2} />
      <Image width={width} src={torisetu3} />
      <Image width={width} src={torisetu4} />
      <Image width={width} src={torisetu5} />
      <Image width={width} src={torisetu6} />
      <Image width={width} src={torisetu7} />
      <Image width={width} src={torisetu8} />
      <Image width={width} src={torisetu9} />
      <Image width={width} src={torisetu10} />
      <Image width={width} src={torisetu11} />
      <Image width={width} src={torisetu12} />
      <Image width={width} src={torisetu13} />
      <Image width={width} src={torisetu14} />
      <Image width={width} src={torisetu15} />
      <Image width={width} src={torisetu16} />
      <Image width={width} src={torisetu17} />
      <Image width={width} src={torisetu18} />
      <Image width={width} src={torisetu19} />
      <Image width={width} src={torisetu20} />
      <Image width={width} src={torisetu21} />
      <Image width={width} src={torisetu22} />
      <Image width={width} src={torisetu23} />
      <Image width={width} src={torisetu24} />
      <Image width={width} src={torisetu25} />

      <p>
        <iframe
          width="1020"
          height="630"
          src="https://www.youtube.com/embed/eFy975wu11M?si=Ct34jjBKL75HVqXC"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </p>
    </div>
  );
};

export default UsersManual;
