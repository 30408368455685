import React, { useEffect, useState, useRef } from "react";
import { Navigate } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import "./App.css";
import { db, auth } from "./FirebaseConfig";
import {
  PlusOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  limit,
  collection,
  getDocs,
  deleteDoc,
  doc,
  query,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import {
  Card,
  Image,
  Spin,
  Modal,
  Tooltip,
  FloatButton,
  Input,
  Form,
  Collapse,
  Empty,
  message,
  Button,
  Tour,
  Typography,
} from "antd";

const { Search } = Input;
const { Text } = Typography;
const { Panel } = Collapse;
const { TextArea } = Input;

function jsonEdit(json) {
  return json.map((row) => ({
    cultiverName: row.cultiverName,
    date: row.date,
    count: row.size,
    aperture: row.aperture,
    temperture: row.temperture,
    humidity: row.humidity,
    wether: row.wether,
    location: row.location,
    important: row.important,
    freetext: row.freeText,
    imageName: row.photopath,
  }));
}

const handleSubmit = async (e) => {
  e.preventDefault();
  message.error("ダウンロード可能なデータがありません");
};

function handleSubmitSuc() {
  message.success("ダウンロード開始");
}

function jsonToCsv(json, delimiter) {
  json = jsonEdit(json);
  const header = Object.keys(json[0]).join(delimiter) + "\n";
  const body = json
    .map((d) =>
      Object.keys(d)
        .map((key) => d[key])
        .join(delimiter)
    )
    .join("\n");
  return header + body;
}

function exportCSV(items, delimiter, filename) {
  const csv = jsonToCsv(items, delimiter);
  const extention = delimiter === "," ? "csv" : "tsv";
  const exportedFilename = (filename || "export") + "." + extention;
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function jsonEdit2(json) {
  return json.map((row, index) => ({
    StomataID: index + 1,
    title: row.title,
    aperture: row.aperture,
    area: row.area,
    bottom: row.location.bottom,
    top: row.location.top,
    right: row.location.right,
    left: row.location.left,
  }));
}

function jsonToCsv2(json, delimiter) {
  json = jsonEdit2(json);
  const header = Object.keys(json[0]).join(delimiter) + "\n";
  const body = json
    .map((d) =>
      Object.keys(d)
        .map((key) => d[key])
        .join(delimiter)
    )
    .join("\n");
  return header + body;
}

function exportCSV2(items, delimiter, filename) {
  console.log(items);
  handleSubmitSuc();
  const csv = jsonToCsv2(items, delimiter);
  const extention = delimiter === "," ? "csv" : "tsv";
  const exportedFilename = (filename || "export") + "." + extention;
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

function getWindowDimensions() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return { width, height };
}

function Storage() {
  const [posts, setPosts] = useState([]);
  const [count, setCount] = useState(100);
  const [loading, setLoading] = useState(true);
  const [delPath, setDelPath] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [searchTerm, setSearchTerm] = useState("");

  const [open, setOpen] = useState(false);
  const [editFields, setEditFields] = useState({});
  const [updateCheck, setUpdateCheck] = useState(true);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [openTour, setOpenTour] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  useEffect(() => {
    async function fetchPosts() {
      setLoading(true);
      const email = await getAuth().currentUser.email;
      const snapShot = await getDocs(
        query(collection(db, email), orderBy("timestamp", "desc"), limit(count))
      );
      setPosts(snapShot.docs.map((doc) => doc.data()));
      setLoading(false);
    }
    fetchPosts();
  }, [count, updateCheck, user]);

  const showModal = (path) => {
    setDelPath(path);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    deleteDocument(delPath);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const increment = () => {
    setCount(posts.length + 100);
  };

  const showEditModal = (post) => {
    setOpen(true);
    setEditFields(post);
  };

  const EdithandleOk = () => {
    setLoading(true);
    update();
  };

  const EdithandleCancel = () => {
    setOpen(false);
  };

  async function update() {
    const washingtonRef = doc(db, editFields.userName, editFields.photopath);
    await updateDoc(washingtonRef, {
      location: editFields.location,
      temperture: editFields.temperture,
      humidity: editFields.humidity,
      wether: editFields.wether,
      freeText: editFields.freeText,
      important: editFields.important,
    });
    setUpdateCheck(!updateCheck);
    setLoading(false);
    setOpen(false);
    message.success("更新しました");
  }

  async function deleteDocument(path) {
    await deleteDoc(doc(db, getAuth().currentUser.email, path.toString()));
    setCount(count - 1);
    message.success("削除しました");
  }

  const filteredPosts = posts.filter((post) =>
    post.cultiverName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const steps = [
    {
      title: "データの表示",
      description:
        "直近の100件を表示しています。さらに過去に遡ってデータを確認する場合は＋ボタンを押してください。",
      target: () => ref1.current,
      placement: "left",
    },
    {
      title: "データのダウンロード",
      description:
        "経時データを取得するにはこちらのボタンを押してください。表示されているデータがcsv形式でダウンロードできます。",
      target: () => ref2.current,
      placement: "left",
    },
    {
      title: "データの削除",
      description:
        "写真ごとにクラウド上からデータを削除ができます。削除したデータは元に戻すことはできません。ただし、スマホ本体のデータは削除されません。",
      target: () => ref3.current,
    },
    {
      title: "データの編集",
      description:
        "写真ごとにクラウド上からデータを編集ができます。編集したデータはいつでも変更が可能です。ただし、スマホ本体のデータは変更されません。",
      target: () => ref4.current,
    },
    {
      title: "データのダウンロード",
      description:
        "写真ごとに気孔の詳細データをダウンロードができます。ただし、取得できるデータは気孔開度(µm)と気孔位置座標のみです。",
      target: () => ref5.current,
    },
  ];

  return (
    <>
      {loading ? (
        <div className="spin">
          <Spin size="large" tip="Loading" />
        </div>
      ) : (
        <>
          {!user ? (
            <Navigate to={"/Top/"} />
          ) : (
            <div>
              <Tour
                open={openTour}
                onClose={() => setOpenTour(false)}
                mask={true}
                steps={steps}
              />
              <div style={{ paddingTop: 60 }}>
                <div
                  style={{
                    position: "fixed",
                    top: 80,
                    marginLeft: 10,
                    zIndex: 1000,
                  }}
                >
                  <Search
                    placeholder="品種の検索"
                    allowClear
                    onSearch={setSearchTerm}
                    style={{ width: "50%", marginBottom: 10 }}
                  />
                  <Button
                    onClick={() => setSearchTerm("")}
                    disabled={!searchTerm}
                    style={{ marginLeft: 10 }}
                  >
                    解除
                  </Button>
                  {searchTerm && <Text> 検索中: {searchTerm}</Text>}
                </div>
                <div className="cards-container">
                  <Image.PreviewGroup
                    preview={{
                      onChange: (current, prev) =>
                        console.log(
                          `current index: ${current}, prev index: ${prev}`
                        ),
                    }}
                  >
                    {filteredPosts.map((post) => (
                      <Card
                        key={post.photopath}
                        className="Strage_Card"
                        style={{
                          width: 280,
                          margin: 10,
                        }}
                        actions={[
                          <Tooltip placement="topLeft" title="削除" color="red">
                            <DeleteOutlined
                              ref={ref3}
                              key="setting"
                              onClick={() => showModal(post.photopath)}
                            />
                          </Tooltip>,
                          <Tooltip
                            placement="topLeft"
                            title="編集"
                            color="green"
                          >
                            <EditOutlined
                              ref={ref4}
                              key="edit"
                              onClick={() => showEditModal(post)}
                            />
                          </Tooltip>,
                          <Tooltip
                            placement="topLeft"
                            title="ダウンロード"
                            color="blue"
                          >
                            {post.results ? (
                              <CloudDownloadOutlined
                                ref={ref5}
                                key="ellipsis"
                                onClick={() =>
                                  exportCSV2(
                                    post.results,
                                    ",",
                                    post.photopath.split(".")[0]
                                  )
                                }
                              />
                            ) : (
                              <CloudDownloadOutlined onClick={handleSubmit} />
                            )}
                          </Tooltip>,
                        ]}
                        cover={
                          <div className="box">
                            <Image
                              alt="写真が保存されていません。"
                              src={`https://firebasestorage.googleapis.com/v0/b/stomatascope-fdf90.appspot.com/o/${
                                post.userName
                              }%2F${encodeURI(post.cultiverName)}%2F${
                                post.photopath
                              }?alt=media&token=bb06e309-ca5b-4b6b-9bdd-7ea80efa4ced`}
                              className="card-image"
                            />
                            <div className="important_item">
                              {post.important}
                            </div>
                            <div className="pathText">
                              {post.photopath.split(".")[0]}
                            </div>
                          </div>
                        }
                      >
                        <div>
                          <div className="resultText">撮影日時:{post.date}</div>
                          <div
                            className="resultText"
                            style={{
                              display: "block",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            <Tooltip
                              placement="topLeft"
                              title={post.cultiverName}
                              color="green"
                            >
                              品種：{post.cultiverName}
                            </Tooltip>
                          </div>
                          <div className="resultText">
                            平均気孔開度（µｍ）：{post.aperture}
                          </div>
                          <div className="resultText">
                            気孔の数（個）：{post.size}
                          </div>
                          <div
                            className="resultText"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            撮影場所：{post.location}
                          </div>
                          <div className="resultText">
                            温度（℃）：{post.temperture}
                          </div>
                          <div className="resultText">
                            湿度（％）：{post.humidity}
                          </div>
                          <div className="resultText">天気：{post.wether}</div>
                          <div
                            className="resultText"
                            style={{
                              display: "block",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            <Tooltip
                              placement="topLeft"
                              title={post.freeText}
                              color="green"
                            >
                              自由記入欄：{post.freeText}
                            </Tooltip>
                          </div>
                          <br />
                          <Collapse accordion size="small" bordered={false}>
                            <Panel header="気孔開度詳細">
                              {post.results ? (
                                post.results.map((results, index) => (
                                  <div key={index}>
                                    <Text>{index + 1}: </Text>
                                    <Text>
                                      {results.idClass > 0 && "Close"}
                                    </Text>
                                    <Text>
                                      {results.idClass <= 0 && "Open"}
                                      {results.aperture > 0 &&
                                        " " +
                                          results.aperture.toFixed(2) +
                                          "µm"}
                                      {results.aperture <= 0 && ""}{" "}
                                      {results.area.toFixed(0) > 0 &&
                                        results.area.toFixed(1) + "µm2"}
                                      {results.area.toFixed(0) <= 0 && ""}
                                    </Text>
                                  </div>
                                ))
                              ) : (
                                <Empty />
                              )}
                              <br />
                            </Panel>
                          </Collapse>
                        </div>
                      </Card>
                    ))}
                  </Image.PreviewGroup>
                </div>
              </div>
              <FloatButton.Group shape="square" style={{ right: 94 }}>
                <Tooltip
                  placement="left"
                  title="さらに100件を読み込む"
                  color="green"
                >
                  <FloatButton
                    ref={ref1}
                    icon={<PlusOutlined />}
                    onClick={increment}
                  />
                </Tooltip>
                <Tooltip
                  placement="left"
                  title="経時データダウンロード"
                  color="green"
                >
                  <FloatButton
                    ref={ref2}
                    icon={<CloudDownloadOutlined />}
                    onClick={() => exportCSV(posts, ",", "stomatascope_data")}
                  />
                </Tooltip>
                <Tooltip placement="left" title="使い方を見る" color="green">
                  <FloatButton
                    onClick={() => setOpenTour(true)}
                    icon={<QuestionCircleOutlined />}
                  />
                </Tooltip>
              </FloatButton.Group>
              <Modal
                title="このデータを削除しますか？"
                open={isModalOpen}
                onOk={handleOk}
                okType="danger"
                okText="はい"
                onCancel={handleCancel}
                cancelText="いいえ"
              >
                <p>
                  "はい"選択すると、データベースからデータが削除されます。スマートフォン内のデータは削除しません。
                </p>
              </Modal>
              <Modal
                open={open}
                title="データを編集する"
                onOk={EdithandleOk}
                onCancel={EdithandleCancel}
                footer={[
                  <Button key="back" onClick={EdithandleCancel}>
                    キャンセル
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={EdithandleOk}
                  >
                    変更
                  </Button>,
                ]}
              >
                <Form>
                  {[
                    { label: "撮影日時", key: "date", disabled: true },
                    { label: "品種", key: "cultiverName", disabled: true },
                    {
                      label: "平均気孔開度(µm)",
                      key: "aperture",
                      disabled: true,
                    },
                    { label: "気孔の数（個）", key: "size", disabled: true },
                    { label: "撮影場所", key: "location" },
                    {
                      label: "温度（℃）",
                      key: "temperture",
                      type: "number",
                      min: -10,
                      max: 50,
                    },
                    {
                      label: "湿度（％）",
                      key: "humidity",
                      type: "number",
                      min: 0,
                      max: 100,
                    },
                    { label: "天気", key: "wether" },
                    {
                      label: "自由記入欄",
                      key: "freeText",
                      component: TextArea,
                      rows: 4,
                    },
                    { label: "重要度", key: "important" },
                  ].map(({ label, key, ...props }) => (
                    <div key={key}>
                      <div className="resultText">{label}</div>
                      <Input
                        {...props}
                        placeholder={label}
                        key={editFields.photopath + key}
                        defaultValue={editFields[key]}
                        value={editFields[key]}
                        onChange={(event) =>
                          setEditFields({
                            ...editFields,
                            [key]: event.target.value,
                          })
                        }
                      />
                    </div>
                  ))}
                </Form>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Storage;
