import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { Navigate, Link } from "react-router-dom";
import {
  Input,
  Button,
  Form,
  message,
  Spin,
  Divider,
  Table,
  Badge,
} from "antd";
import { LoginOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";

const validateMessages = {
  types: {
    email: "有効なメールアドレスではありません。",
  },
};

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      message.success(`${loginEmail}としてログインしました`);
    } catch (error) {
      message.warning("メールアドレスまたはパスワードが間違っています");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "日付",
      dataIndex: "date",
      key: "date",
      width: "140px",
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "お知らせ内容",
      dataIndex: "message",
      key: "message",
      responsive: ["md"],
      sorter: (a, b) => a.message.localeCompare(b.message),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "関連リンク",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <a href={text} target="_blank" rel="noreferrer">
          {text}
        </a>
      ),
      responsive: ["md"],
      sorter: (a, b) => a.link.localeCompare(b.link),
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  const data = [
    {
      key: "1",
      date: "2023/10/10",
      message: "正式リリース開始（AIモデル　トマトver1、ナス、イチゴ）",
      link: "https://prtimes.jp/main/html/rd/p/000000013.000053860.html",
    },
    {
      key: "2",
      date: "2023/12/13",
      message: "新規代理店が追加されました【エスペックミック株式会社様】",
      link: "https://www.especmic-agri.com/post/231208",
    },
    {
      key: "3",
      date: "2024/4/23",
      message: "AIモデル　レタスが追加されました",
      link: "",
    },
    {
      key: "4",
      date: "2024/4/23",
      message:
        "AIモデル　コムギ、キャベツのテストリリースAIモデルが追加されました",
      link: "",
    },
    {
      key: "5",
      date: "2024/4/23",
      message:
        "AIモデル　コムギ、キャベツのテストリリースAIモデルが追加されました",
      link: "",
    },
    {
      key: "6",
      date: "2024/4/23",
      message:
        "AIモデル　コムギ、キャベツのテストリリースAIモデルが追加されました",
      link: "",
    },
    {
      key: "7",
      date: "2024/3/1",
      message: "新規代理店が追加されました【アズワン株式会社様】",
      link: "https://axel.as-1.co.jp/asone/d/68-5328-93/",
    },
    {
      key: "8",
      date: "2024/3/1",
      message:
        "スマート農業技術カタログ（施設園芸）166に掲載されました【農林水産省】",
      link: "https://www.maff.go.jp/j/kanbo/smart/smart_agri_technology/smartagri_catalog_shisetsu.html",
    },
    {
      key: "9",
      date: "2024/7/24",
      message:
        "GPEC（施設園芸・植物工場展）エスペックミック(株)様のブースにてデモを実施中",
      link: "https://www.gpec.jp/search/index.php?p=detail&id=37",
    },
  ];

  return (
    <div>
      {user ? (
        <Navigate to="/" />
      ) : (
        <div style={{ textAlign: "center" }}>
          {loading ? (
            <Spin size="large" style={{ margin: 200 }} tip="読み込み中" />
          ) : (
            <div>
              <Form
                name="nest-messages"
                onFinish={handleSubmit}
                validateMessages={validateMessages}
              >
                <h3>ログインする</h3>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "メールアドレスは必須です。",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="email"
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                    style={{ width: 500 }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "パスワードを入力してください",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    visibilityToggle={{
                      visible: passwordVisible,
                      onVisibleChange: setPasswordVisible,
                    }}
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    style={{ width: 500 }}
                  />
                </Form.Item>
                <p>
                  パスワードが分からない方は
                  <Link to="/ResetPassword">こちら</Link>
                </p>
                <p>
                  <Link to="/Top">製品情報を知りたい方はこちら</Link>
                </p>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<LoginOutlined />}
                >
                  ログイン
                </Button>
              </Form>

              <div style={{ padding: 60 }}>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
