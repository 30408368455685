/* Login.js（完成版） */

import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
/* 「Link」をimport↓ */
import { Navigate, Link } from "react-router-dom";
import { Input, Button, Form, message, Spin } from "antd";
import { UserOutlined, ExportOutlined } from "@ant-design/icons";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  types: {
    email: "有効なメールアドレスではありません。",
  },
};
const onFinish = (values) => {
  console.log(values);
};
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        message.success(email + "にパスワード再設定のメールを送りました。");
        setLoading(false);
      })
      .catch((error) => {
        message.warning("メールアドレスが有効ではありません。");
        setLoading(false);
        // ..
      });
  };

  const [user, setUser] = useState();

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  });

  return (
    <div
      className="center"
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        lineHeight: "100px",
      }}
    >
      {user ? (
        <Navigate to={`/`} />
      ) : (
        <div>
          {loading && <Spin size="large" style={{ margin: 200 }} />}
          {!loading && (
            <div>
              <Form
                onSubmit={handleSubmit}
                name="nest-messages"
                onFinish={onFinish}
                validateMessages={validateMessages}
              >
                <h3>パスワードのリセット</h3>
                <h4>メールアドレス</h4>

                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  style={{ width: 500 }}
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                />

                <br></br>
                <br></br>
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  icon={<ExportOutlined />}
                >
                  送信
                </Button>
                {/* ↓リンクを追加 */}

                <p>
                  ログインは<Link to={`/login/`}>こちら</Link>
                </p>
              </Form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
