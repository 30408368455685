import { SidemenuData } from "./SidemenuData";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { Tooltip, Button, Modal, message } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { ReactComponent as Icon } from "./LogWhite.svg";
import { LogoutOutlined } from "@ant-design/icons";
import { Navigate, Link } from "react-router-dom";
const SideMenu = () => {
  /* ↓state変数「user」を定義 */
  const [user, setUser] = useState("");

  /* ↓ログインしているかどうかを判定する */
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  /* ↓関数「logout」を定義 */
  const logout = async () => {
    await signOut(auth);
    navigate("/login/");
    message.success("ログアウトしました。");
    setOpen(false);
  };
  return (
    <div>
      {!user ? (
        <div className="SidebarList">
          <a href="https://happy-quality.jp/" target="_blank" rel="noreferrer">
            <div className="AvaterIcon">
              <Icon width={100} height={100} />
            </div>
          </a>
          <div className="AvaterIcon">
            <Link to={`/Login/`}>
              <Button>ログインする</Button>
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <ul className="SidebarList">
            {/* プロパティの指定方法 */}
            <a
              href="https://happy-quality.jp/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="AvaterIcon">
                <Icon width={100} height={100} />
              </div>
            </a>
            <div className="AvaterIcon">
              <Tooltip placement="right" title={user.email} color="green">
                <SmileOutlined style={{ fontSize: "30px", color: "#FFFF00" }} />
              </Tooltip>
            </div>

            {SidemenuData.map((value, key) => {
              return (
                <li
                  key={key}
                  id={window.location.pathname === value.link ? "active" : ""}
                  className="row"
                  onClick={() => navigate(value.link)}
                >
                  <div id="icon">{value.icon}</div>
                  <div id="title">{value.title}</div>
                </li>
              );
            })}
            <br></br>
            <br></br>
            <br></br>

            <div className="AvaterIcon">
              <Button
                danger
                className="AvaterIcon"
                onClick={showModal}
                icon={<LogoutOutlined />}
              >
                ログアウトする
              </Button>
            </div>
          </ul>
        </div>
      )}
      <Modal
        title={"ログアウト"}
        open={open}
        onOk={logout}
        onCancel={hideModal}
        okText="OK"
        cancelText="キャンセル"
      >
        <div>ログアウトしてトップに戻りますか？</div>
      </Modal>
    </div>
  );
};

export default SideMenu;
