import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./FirebaseConfig.js";
import { Navigate } from "react-router-dom";
import { Spin, Typography, Image, Button, Row, Col } from "antd";
import PlantCard from "./PlantCard";
import TopbackGroundImage from "./assets/images/LogoStomataScope.png";
import {
  tomatoIcon,
  momotarouImg,
  eggplantIcon,
  eggplantImg,
  strawberryIcon,
  strawberryImg,
  komugiIcon,
  komugiImg,
  lettuceIcon,
  roseIcon,
  roseImg,
  cabbageIcon,
  siroinunadunaIcon,
  siroinunadunaImg,
  komatuna,
  letticeImg,
  cabbageImg,
  komatunaImg,
  userImage,
  savedataimage,
  smartphone,
  userImage2,
} from "./images";
import { MailOutlined } from "@ant-design/icons";
const { Title } = Typography;

const Top = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  if (user) return <Navigate to={`/`} />;
  if (loading)
    return <Spin size="large" style={{ margin: 200 }} tip="Loading" />;

  return (
    <div style={{ padding: 0 }}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div
        style={{
          position: "fixed",
          top: 80,
          marginLeft: 10,
          zIndex: 1000,
        }}
      >
        <Button type="primary" icon={<MailOutlined />}>
          <a
            href="https://happy-quality.jp/contact/"
            target="_blank"
            rel="noreferrer"
          >
            　見積依頼、デモ機等のお問い合わせはこちら
          </a>
        </Button>
      </div>
      <div className="center-image">
        <img src={TopbackGroundImage} width={"100%"} alt="Top Background" />
      </div>
      　Stomata Scopeは株式会社Happy Qualityの登録商標です。商標登録番号
      登録6777557
      <br />
      <Title level={2}>　現場の生きた気孔データを観察できる</Title>
      <Title level={4} type="secondary" className="text">
        　Stomata
        Scopeは、誰でも簡単に生きた気孔を観察することができます。カメラとピント調整機能により、葉の凹凸など微細な構造をクリアにした高解像度撮影が可能となっています。
      </Title>
      <div>
        <Image src={userImage} width={400} alt="User" />
        <Image src={userImage2} width={400} alt="User2" />
      </div>
      <Title level={2}>　様々な環境・品目に対応</Title>
      <Title level={4} type="secondary">
        　手軽に持ち運びができるデバイスのため、様々な環境で栽培されている植物の気孔を観察することができます。撮影場所や環境データなどを撮影データと共に保存することが可能となっています。
      </Title>
      <div>
        <Image src={savedataimage} width={400} alt="Save Data" />
      </div>
      <Title level={2}>　AIを活用した検出</Title>
      <Title level={4} type="secondary">
        　AIを活用することで、気孔の数や場所、気孔開度の自動計測を行うことが可能となりました。解析結果も撮影して数秒で解析が完了し、すぐに結果をご覧いただけます
      </Title>
      <div>
        <Image src={smartphone} width={400} alt="Smartphone" />
      </div>
      <p>
        <iframe
          width="500"
          height="300"
          src="https://www.youtube.com/embed/eFy975wu11M?si=Ct34jjBKL75HVqXC"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </p>
      <Title level={5} type="secondary">
        　※AIが実装されている品目に限ります
      </Title>
      <Title level={2}>　撮影画像サンプル</Title>
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <Row gutter={[16, 16]}>
          <Col>
            <PlantCard
              coverImage={momotarouImg}
              avatarImage={tomatoIcon}
              title="トマト"
              description="Published"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={eggplantImg}
              avatarImage={eggplantIcon}
              title="ナス"
              description="Published"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={strawberryImg}
              avatarImage={strawberryIcon}
              title="イチゴ"
              description="Published"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={komugiImg}
              avatarImage={komugiIcon}
              title="コムギ"
              description="Published"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={letticeImg}
              avatarImage={lettuceIcon}
              title="レタス"
              description="Published"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={cabbageImg}
              avatarImage={cabbageIcon}
              title="キャベツ"
              description="Published"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={roseImg}
              avatarImage={roseIcon}
              title="バラ"
              description="Coming Soon"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={siroinunadunaImg}
              avatarImage={siroinunadunaIcon}
              title="シロイヌナズナ"
              description="Coming Soon"
            />
          </Col>
          <Col>
            <PlantCard
              coverImage={komatunaImg}
              avatarImage={komatuna}
              title="コマツナ"
              description="Coming Soon"
            />
          </Col>
        </Row>
      </Image.PreviewGroup>
    </div>
  );
};

export default Top;
